.App {
  font-family: sans-serif;
  text-align: center;
  width: 500px; /* Remove the space before 'px' */
  margin: 50px auto;
  padding: 30px 20px; /* Remove the spaces before 'px' */
  background-color: #add8e6; /* This is AliceBlue */
  color: black; /* A dark text color for contrast and readability */
  border-radius: 20px; /* Remove the space before 'px' */
  border: 5px solid black; /* Remove the space before 'px' */
}
.cnt {
  margin: 10px;
  width: 25px;
  padding: 2px;
  border-radius: 5px;
  box-shadow: 5px 5px 5px black;
  font-weight: bold; /* This makes the text bolder */
  font-size: 16px; /* Adjust the size as needed. This example uses 24 pixels */
  padding: 5px 5px; /* Add padding for better appearance */
  cursor: pointer; /* Change the cursor to a hand pointer on hover */
}
button {
  border-radius: 5px;
  box-shadow: 5px 5px 5px black;
}
